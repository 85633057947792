*, :before, :after {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  font-family: sans-serif;
  line-height: 1.15;
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  color: #212529;
  text-align: left;
  background-color: #fff;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title], abbr[data-original-title] {
  text-decoration: underline;
  cursor: help;
  text-decoration-skip-ink: none;
  border-bottom: 0;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #007bff;
  background-color: #0000;
  text-decoration: none;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre, code, kbd, samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

pre {
  -ms-overflow-style: scrollbar;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  vertical-align: middle;
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

caption {
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
  padding-top: .75rem;
  padding-bottom: .75rem;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  margin-bottom: .5rem;
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input, button, select, optgroup, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

input[type="radio"], input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  resize: vertical;
  overflow: auto;
}

fieldset {
  min-width: 0;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  width: 100%;
  max-width: 100%;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
  margin-bottom: .5rem;
  padding: 0;
  display: block;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  cursor: pointer;
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

/*# sourceMappingURL=index.52562b2e.css.map */
